<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Sözleşme Bilgileri</span>
        </template>
        <b-card
          title="Sözleşme Oluştur"
        >
          <validation-observer ref="simpleRules">
            <contrat-form />

          </validation-observer>
        </b-card>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              :disabled="dataItem.submitData"
              @click="submitData"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.$route.params.id" />
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import ContratForm from '@/views/Admin/Contracts/Components/ContractForm.vue'

export default {
  name: 'ContractsAdd',
  components: {
    ContratForm,
    BTabs,
    CustomerHistory,
    CustomerInvoice,
    CustomerInfo,
    BTab,
    BCol,
    BCard,
    BRow,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['contracts/dataItem']
    },
    saveData() {
      return this.$store.getters['contracts/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push(`/app/contracts/view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('contracts/RESET_DATA_ITEM')
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id)
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_customers = this.$route.params.id
          this.$store.dispatch('contracts/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
